import { createTheme } from '@material-ui/core/styles';


const theme =  createTheme({
  
  palette: {
    type:'light',
    icacBlue: {
      light:'#42558f',
      main: '#132B73',
    },
    activeNavItem: {
      main: '#0d1e50'
    },
    navBar: {
      main:'#132B73',
    },
    rows: {
      main: '#f9f9f9'
    },

    idsContainerHeaderBarGradient: {
      main: 'linear-gradient(to bottom, white 10%, #E9E9E9 100%)'
    },
    headingTextIcon: {
      main:'black'
    },
    headerColor: {
      main: 'white'
    },
    primary: {
      main: '#132B73'
    },
    secondary: {
      main: '#d9534f'
    },
    success: {
      main: '#5cb85c'
    },
    background: {
      main: '#eeeeee',

    },
    foreground: {
      main:'white',
    }

  },

  typography: {
    fontFamily: [
      'Open Sans'
    ]
  }
});



export default theme;




