/*
 * Title:				Loading.js
 * Name:				Loading Spinner Component
 * Version:				1.0
 * Modification Date:	07/10/21
 * Author:				Amanda Thompson
 * Purpose:				Indicates a page is loading/data is being processed
 */
import Box from "@material-ui/core/Box";
import React from "react";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/react";
import { makeStyles } from "@material-ui/core/styles";

const override = css`
  display: block;
  margin: 0 auto;
`;

const useStyles = makeStyles({
  container: {
    width: "500px",
    height: "150px",
    marginLeft: "50%",
    display: "flex",
    paddingTop: "20px",

    // justifyContent: "center",
    // alignItems: "center",
  },
  containerSpin: {
    width: "500px",
    paddingTop: "20px",

    marginLeft: "50%",
    display: "flex",
  },
  message: {
    textAlign: "center",
    fontSize: "1.5em",
  },
});

export default function Loading({ message }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.containerSpin}>
        <Box sx={{ display: "flex" }}>
          <BounceLoader size={60} color="blue" css={override} loading />
        </Box>
      </div>
      <div className={classes.container}>
        <span className={classes.message}>
          {message ? message : "Loading..."}
        </span>
      </div>
    </>
  );
}

// Rahul's Loader:
// import * as React from "react";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import Box from "@material-ui/core/Box";

// export default function Loading() {

//   return (
//     <Box sx={{ display: "flex" }}>
//       <CircularProgress />
//     </Box>
//   );
// }
