import { useEffect, useState } from "react";
const useInactivityTimer = (
  startTime,
  showTime,
  showInactivityDialog,
  setShowInactivityDialog
) => {
  const [timer, setTimer] = useState(startTime);
  const resetTimeout = () => {
    setTimer(startTime);
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    for (let i in events) {
      if (!showInactivityDialog) {
        window.addEventListener(events[i], resetTimeout);
      }
    }
    return () => {
      clearInterval(myInterval);
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  });

  useEffect(() => {
    if (timer < showTime) {
      setShowInactivityDialog(true);
    }
  });
  return [timer, resetTimeout];
};

export default useInactivityTimer;
