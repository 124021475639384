import { createTheme } from '@material-ui/core/styles';


const theme =  createTheme({
  
  palette: {
    type:'dark',
    navBar: {
      main:'#424242',
    },
    activeNavItem: {
      main: '#616161'
    },
    icacBlue: {
      light:'#42558f',
      main: '#132B73',
      },
    idsContainerHeaderBarGradient: {
      main: 'linear-gradient(to bottom, #616161 10%, #343434 100%)'
    },
    rows: {
      main: '#303030'
    },
    headerColor: {
        main: '#ff9800'
    },
    headingTextIcon: {
      main: '#ff9800'
    },
    primary: {
      main: '#ff9800'
    },
    secondary: {
      main: '#808080'
    },
    success: {
      main: '#ff9800'
    },
    background: {
      main: '#303030',

    },
    foreground: {
      main:'#424242',
    }
  },

  typography: {
    fontFamily: [
      'Open Sans'
    ]
  }
});



export default theme;




