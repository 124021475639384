import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Redirect, Route } from "react-router-dom";

const NotFoundRoute = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const render = (props) => {
    if (isAuthenticated) {
      return <Redirect {...props} to="/not_found" />;
    }

    return <Redirect {...props} to="/landing/login" />;
  };

  return <Route render={render} />;
};

export default NotFoundRoute;
