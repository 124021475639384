import React, { useState, useContext } from "react";
import Router from "./components/routing/Router";
import routes from "./components/routing/config";
import { DarkModeContext } from "./context/DarkModeContext";
import { AuthContext } from "./context/AuthContext";

import lightTheme from "./lightTheme";
import darkTheme from "./darkTheme";
import { ThemeProvider } from "@material-ui/core/styles";
// import { changeDarkMode } from "./actions/styleActions";
import Switch from "@material-ui/core/Switch";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useInactivityTimer from "./components/inactivity/useAutoTimeout";
import InactivityDialog from "./components/inactivity/Inactivity";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { position: "fixed", zIndex: 10001, marginTop: "10px" },
  })
);

const App = () => {
  const classes = useStyles();
  const { darkMode, switchDarkMode } = useContext(DarkModeContext);
  const { isAuthenticated, handleLogout } = useContext(AuthContext);

  // Inactivity Timer
  const INACTIVITY_TIME = 1200;
  const DIALOG_TIME = 60;
  const [showInactivityDialog, setShowInactivityDialog] = useState(false);
  const [timer, resetTimeout] = useInactivityTimer(
    INACTIVITY_TIME,
    DIALOG_TIME,
    showInactivityDialog,
    setShowInactivityDialog
  );

  return (
    <>
      <ThemeProvider theme={darkMode === true ? darkTheme : lightTheme}>
        <div style={{ float: "right", width: "100px" }}>
          <FormControlLabel
            className={classes.root}
            labelPlacement="bottom"
            control={<Switch onChange={switchDarkMode} checked={darkMode} />}
          />
          {isAuthenticated && (
            <InactivityDialog
              open={showInactivityDialog}
              setOpen={setShowInactivityDialog}
              timer={timer}
              resetTimeout={resetTimeout}
              logout={handleLogout}
            />
          )}
        </div>
        <Router routes={routes} />
      </ThemeProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        closeOnClick={true}
        hideProgressBar={true}
      />
    </>
  );
};

export default App;
