import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import Paper from "@material-ui/core/Paper";

import App from "./App";
import history from "./history";

import "./index.css";

import { DarkModeContextProvider } from "./context/DarkModeContext";
import { AuthContextProvider } from "./context/AuthContext";

ReactDOM.render(
    <AuthContextProvider>
      <Router history={history}>
        <Paper>
          <DarkModeContextProvider>
            <App />
          </DarkModeContextProvider>
        </Paper>
      </Router>
    </AuthContextProvider>,
  document.getElementById("root")
);
