import React, { useState, createContext, useEffect } from "react";
import jwtDecode from "jwt-decode";
import api from "../utils/api";
import useLocalStorage from "../hooks/useLocalStorage";

const AuthContext = createContext();

function AuthContextProvider(props) {
  const [account, setAccount] = useLocalStorage("account", {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    middleInitial: null,
    accountStatus: null,
    profiles: [],
    activeProfile: null,
    activeProfileAgency: null,
  });

  const [userAccess, setUserAccess] = useState({
    isAdmin: false,
    isCommander: false,
    isUser: false,
    isPMUser: false,
    isCaseUser: false,
    isPmMgr: false,
    isCaseMgr: false,
  });

  const [loginState, setLoginState] = useState({
    isAuthenticated: false,
    loading: true,
    permissions: [],
  });
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useLocalStorage("token", null);
  // eslint-disable-next-line no-unused-vars
  const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", null);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));

    if (token && refreshToken) {
      handleLogin(token, refreshToken);
      // setLoginState({
      //   isAuthenticated: true,
      //   loading: false,
      //   permissions: [],
      // });
    } else {
      setLoginState({
        isAuthenticated: false,
        loading: false,
        permissions: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleLogin(token, refreshToken) {
    setToken(token);
    setRefreshToken(refreshToken);

    const { account, profile } = jwtDecode(token);
    const accountId = account.id;
    const profileId = profile.id;
    const agency = profile.agency;
    const permissions = profile.permissions;

    try {
      // getting full account details
      const { data } = await api.get(`/core/accounts/${accountId}/full/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      const fullAccount = data;

      const {
        id,
        email,
        profiles,
        first_name: firstName,
        last_name: lastName,
        middle_initial: middleInitial,
        account_status: accountStatus,
      } = fullAccount;

      setAccount({
        ...account,
        id,
        email,
        profiles,
        firstName,
        lastName,
        middleInitial,
        accountStatus,
        activeProfile: profileId,
        activeProfileAgency: agency,
      });

      if (profiles) {
        let roles = profiles[0].roles;
        setUserAccess({
          isAdmin: !!roles.find((role) => role.name === "Administrator"),
          isCommander: !!roles.find((role) => role.name === "Commander"),
          isUser: true,
          isPMUser: !!roles.find(
            (role) => role.name === "Performance Measure User"
          ),
          isCaseUser: !!roles.find(
            (role) => role.name === "Case Referral User"
          ),
          isPmMgr: !!roles.find(
            (role) => role.name === "Performance Measure Manager"
          ),
          isCaseMgr: !!roles.find(
            (role) => role.name === "Case Referral Manager"
          ),
        });
      }

      setLoginState({
        ...loginState,
        isAuthenticated: true,
        loading: false,
        permissions: permissions,
      });
    } catch (err) {
      console.log(err);
      // Handle login error
      handleLogout();
    }
  }

  // const restoreLogin = () => {};

  const handleLogout = () => {
    setToken(null);
    setRefreshToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("disclaimer");
    setAccount({
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      middleInitial: null,
      accountStatus: null,
      profiles: [],
      activeProfile: null,
      activeProfileAgency: null,
    });
    setLoginState({
      ...loginState,
      isAuthenticated: false,
      loading: false,
      permissions: [],
    });
    window.location.reload(true);
  };

  return (
    <div>
      <AuthContext.Provider
        value={{
          account,
          setAccount,
          handleLogin,
          handleLogout,
          isAuthenticated: loginState.isAuthenticated,
          loading: loginState.loading,
          permissions: loginState.permissions,
          userAccess,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    </div>
  );
}

export { AuthContext, AuthContextProvider };
