import React, { Suspense, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Redirect, Route } from "react-router-dom";
// import PropTypes from "prop-types";

const ICACRoute = ({
  path,
  exact,
  redirect,
  component: Component,
  fallback,
  privateRoute,
  routes
}) => {
  const { isAuthenticated, loading } = useContext(AuthContext);
  const render = (props) => {
    let filteredRoutes = [];

    if (routes) {
      filteredRoutes = routes.map((route) => {
        if (!route.hasOwnProperty("privateRoute")) {
          route.privateRoute = privateRoute;
        }

        return route;
      });
    }

    if (redirect) {
      return <Redirect {...props} to={redirect} />;
    }

    if (loading) {
      return fallback;
    }

    if (!privateRoute && isAuthenticated) {
      return <Redirect {...props} to="/" />;
    } else if (privateRoute && !isAuthenticated) {
      return <Redirect {...props} to="/landing/login" />;
    } else {
      return <Component {...props} routes={filteredRoutes} />;
    }
  };

  return (
    <Suspense fallback={fallback}>
      <Route path={path} exact={exact} render={render} />
    </Suspense>
  );
};

export default ICACRoute;
